import React, {useCallback, useEffect, useState, memo, useRef} from 'react';
import {Autocomplete, IconButton, Paper, SvgIcon, TextField, Tooltip, Typography} from "@mui/material";
import XIcon from "@untitled-ui/icons-react/build/esm/XClose";
import useOmniaApi from "../../../hooks/use-omnia-api";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import {styled} from "@mui/system";
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import {useTranslation} from "react-i18next";

const StyledPaper = styled(Paper)({
    minWidth: '300px !important',
});

function SmartSelector({options, endpoint, query, error, helperText, label, multiple, values, disabled, handleChange, onChange, onInputChange, onSelectionLoad, name, labelField, identField, translate, ...rest}) {

    const [opt, setOpt] = useState([]);
    const [selectedOpt, setSelectedOpt] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [allOptions, setAllOptions] = useState(false);
    const [loadingSelected, setLoadingSelected] = useState(false);
    const { get, post } = useOmniaApi();
    const allOptionsRef = useRef();
    allOptionsRef.current = allOptions;

    const onChangeHandler = (event, newValue) => {

        if(endpoint){
            if(multiple){
                setInputValue('');
            } else {
                setInputValue(newValue ? newValue[labelField] : '');
            }
            debouncedChangeHandler(null);
        }

        const updateEvent = {
            target: {
                name: name,
                value: newValue ? (multiple ? newValue.map((item) => item[identField]) : newValue[identField]) : null,
                object: newValue
            }
        };

        handleChange(updateEvent);
        if(onChange)
            onChange(updateEvent);
    }

    const loadOptions = (searchTerm) => {
        if(!allOptionsRef.current){
            setLoading(true);
            get(endpoint, {...query, ...(searchTerm ? {search: searchTerm, page: 1, size: 30} : {page: 1, size: 30})}).then((response) => {
                setAllOptions(Array.isArray(response));
                if(Array.isArray(response)){
                    setOpt(response);
                } else {
                    setOpt(response.results || []);
                }
            }).catch(errors => {
                console.log('Smart Selector could not fetch details: ', errors);
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const debouncedChangeHandler = useCallback(debounce(loadOptions, 500), []);

    const handleInputChange = (event, value, reason) => {
        if (reason !== 'reset') {
            // check if value is an object
            if (typeof value !== 'object') {
                setInputValue(value);
            }
            debouncedChangeHandler(value);
        }
    };

    const resetValue = () => {
        if(window.confirm(t('notify.are_you_sure'))){
            if(handleChange)
                handleChange({
                    target: {
                        name: name,
                        value: null
                    }
                })
        }
    }

    const totalOptions = [...opt, ...selectedOpt.filter(e => !opt.map(i => i[identField]).includes(e[identField]))];

    const selectedValue = multiple ? totalOptions.filter((i) => values?.includes(i[identField])) : (totalOptions.find((i) => i[identField] === values) || null);

    const hasDouble = totalOptions.some((option, index) => totalOptions.findIndex((i) => i?.[labelField] === option?.[labelField]) !== index);

    useEffect(() => {
        if(onSelectionLoad){
            onSelectionLoad(multiple ? selectedOpt : selectedOpt[0]);
        }
    }, [selectedOpt, multiple]);

    useEffect(() => {
        // TODO: this is pretty "dump" because it just triggers tons of requests
        if(endpoint){
            setLoadingSelected(true);
            if(multiple && values.length > 0){
                get(endpoint, {[identField + '__in']: values}).then((response) => {
                    setSelectedOpt(response || []);
                }).finally(() => {
                    setLoadingSelected(false);
                })
            }
            if(!multiple && values){
                // get(endpoint, {[identField + '__in']: [values]}).then((response) => {
                get(endpoint, {[identField + '__in']: values}).then((response) => {

                    // Sort manually after the retrieval
                    const rawResponse = response || [];
                    const filteredResponse = rawResponse.filter((item) => multiple ? values.includes(item[identField]) : item[identField] === values);

                    setSelectedOpt(filteredResponse);

                    // This sets the input value initially
                    if(inputValue === '' && filteredResponse.length > 0){
                        setInputValue(filteredResponse[0][labelField]);
                    }

                }).finally(() => {
                    setLoadingSelected(false);
                });
            }
        }
    }, [endpoint, values]);

    useEffect(() => {
        if(endpoint){
            loadOptions(null);
        } else {
            if(typeof(options) !== "undefined"){
                setOpt(options);
            } else {
                setOpt([]);
            }
        }
    }, [options, query]);

    useEffect(() => {
        if(typeof inputValue === 'string'){
            onInputChange?.(inputValue);
        }
    }, [inputValue]);

    if((!multiple && (values && !selectedValue)) || (multiple && (values.length > 0 && selectedValue.length === 0)))
        return (
            <TextField
                label={loadingSelected ? t('common.loading') : t('common.no_data')}
                value={null}
                fullWidth={true}
                disabled={true}
                InputProps={{
                    endAdornment: (
                        <Tooltip title={t('common.reset')}>
                            <IconButton size="small" onClick={resetValue}>
                                <SvgIcon fontSize="small">
                                    <XIcon />
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    )
                }}
                {...rest}
            />
        );

    return (
        <Autocomplete
            options={totalOptions}
            multiple={multiple}
            getOptionLabel={(option) => {
                if(translate){
                    return t(option?.[labelField]) + (hasDouble ? (' #' + option[identField]) : '')
                } else {
                    return option?.[labelField] + (hasDouble ? (' #' + option[identField]) : '')
                }
            }}
            value={selectedValue}
            onChange={onChangeHandler}
            noOptionsText={loading ? t('common.loading') : t('common.sst_no_results')}
            sx={{width: '100%'}}
            disabled={disabled}
            renderTags={(value) => {
                if (multiple) {
                    // If more than 5 items are selected, show a summary
                    if (value.length > 2) {
                        return (
                            <Typography variant="body2" sx={{ pl: 0.5 }}>
                                {value.length} selected
                            </Typography>
                        );
                    } else {
                        // If 5 or fewer items are selected, list them
                        return value.map((option, index) => (
                            <Typography key={option[identField]} variant="body2" sx={{ pl: 0.5 }}>
                                {translate ? t(option[labelField]) : option[labelField]}{index !== value.length - 1 ? ', ' : ''}
                            </Typography>
                        ));
                    }
                }
            }}
            componentsProps={{
                paper: {
                    component: StyledPaper,
                },
            }}
            renderInput={(params) => {

                return (
                    <TextField
                        error={error}
                        helperText={helperText}
                        disabled={disabled}
                        label={label}
                        name={name}
                        {...params}
                        {...rest}
                    />
                )
            }}
            inputValue={translate ? t(inputValue) : inputValue}
            onInputChange={handleInputChange}
        />
    )

}

SmartSelector.propTypes = {
    values: PropTypes.oneOfType([PropTypes.array, PropTypes.number]).isRequired,
    handleChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array,
    error: PropTypes.bool,
    endpoint: PropTypes.string,
    multiple: PropTypes.bool,
    translate: PropTypes.bool,
    disabled: PropTypes.bool,
    query: PropTypes.object,
    helperText: PropTypes.string,
    label: PropTypes.string,
    labelField: PropTypes.string,
    identField: PropTypes.string,
    onChange: PropTypes.func,
}

SmartSelector.defaultProps = {
    error: false,
    endpoint: null,
    touched: false,
    disabled: false,
    translate: false,
    multiple: true,
    query: {},
    labelField: 'name',
    identField: 'id',
    helperText: '',
    label: null
}

export default memo(SmartSelector, (prevProps, nextProps) => {

    const areArraysOfObjectsEqual = (array1, array2, key) => {
        const sortedArray1 = sortBy(array1, key);
        const sortedArray2 = sortBy(array2, key);
        return isEqual(sortedArray1, sortedArray2);
    };

    const valueChecker = {
        endpoint: prevProps.endpoint === nextProps.endpoint,
        options: areArraysOfObjectsEqual(prevProps.options, nextProps.options, prevProps.identField),
        query: isEqual(prevProps.query, nextProps.query),
        error: prevProps.error === nextProps.error,
        helperText: prevProps.helperText === nextProps.helperText,
        label: prevProps.label === nextProps.label,
        multiple: prevProps.multiple === nextProps.multiple,
        values: isEqual(prevProps.values, nextProps.values),
        disabled: prevProps.disabled === nextProps.disabled,
        name: prevProps.name === nextProps.name,
        labelField: prevProps.labelField === nextProps.labelField,
        identField: prevProps.identField === nextProps.identField,
        translate: prevProps.translate === nextProps.translate,
    }

    // Only check on hard values
    return Object.values(valueChecker).every(value => value);
});
