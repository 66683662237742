import MessagesIcon from '@untitled-ui/icons-react/build/esm/MessageChatCircle';
import {Badge, IconButton, SvgIcon, Tooltip} from '@mui/material';
import {usePopover} from 'src/omnia/hooks/use-popover';
import {ChatPopover} from './chat-popover';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useSecurityCheck} from "../../../../hooks/use-security-check";

export const ChatButton = () => {

    const popover = usePopover();
    const { t } = useTranslation();
    const unread = useSelector((state) => state.messages.unread);
    const { hasRights, isModuleInstalled } = useSecurityCheck();

    if(!hasRights(['social_intranet', 'groon_chats'], 'and') || !isModuleInstalled('intranet'))
        return null;

    return (
        <>
            <Tooltip title={t("common.messages")}>
                <IconButton
                    onClick={popover.handleOpen}
                    ref={popover.anchorRef}
                >
                    <Badge
                        color="error"
                        badgeContent={unread > 0 ? unread : 0}
                    >
                        <SvgIcon>
                            <MessagesIcon/>
                        </SvgIcon>
                    </Badge>
                </IconButton>
            </Tooltip>
            <ChatPopover
                anchorEl={popover.anchorRef.current}
                onClose={popover.handleClose}
                open={popover.open}
            />
        </>
    );
};
