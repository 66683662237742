export const ADD_THREAD = '@messages/add-thread';
export const REMOVE_THREAD = '@messages/remove-thread';
export const MUTE_THREAD = '@messages/mute-thread';
export const UNMUTE_THREAD = '@messages/unmute-thread';
export const MARK_THREAD_AS_SEEN = '@messages/mark-thread-as-seen';
export const ADD_MESSAGES = '@messages/send-messages';
export const OPEN_SIDEBAR = '@messages/open-sidebar';
export const CLOSE_SIDEBAR = '@messages/close-sidebar';
export const INITIALIZE_THREADS = '@messages/initialize-threads';

export const ADD_MINI_THREAD = '@messages/add-mini-thread';
export const REMOVE_MINI_THREAD = '@messages/remove-mini-thread';
export const OPEN_MINI_THREAD = '@messages/open-mini-thread';
export const CLOSE_MINI_THREAD = '@messages/close-mini-thread';
export const ADD_NEW_MESSAGE = '@messages/add-new-message';
export const REMOVE_NEW_MESSAGES = '@messages/remive-new-message';
export const UPDATE_LAST_MESSAGE = '@messages/update-last-message';
export const UPDATE_ASSISTANT_OPEN = '@messages/update-assistant-open';
export const UPDATE_ASSISTANT_UNREAD = '@messages/update-assistant-unread-messages';


export function updateAssistantUnread(unread){
    return (dispatch) => dispatch({
        type: UPDATE_ASSISTANT_UNREAD,
        payload: unread
    });
}


export function updateAssistantOpen(open){
    return (dispatch) => dispatch({
        type: UPDATE_ASSISTANT_OPEN,
        payload: open
    });
}

export function addLatestMessage(threadId, message){
    return (dispatch) => dispatch({
        type: UPDATE_LAST_MESSAGE,
        payload: {
            threadId,
            message
        }
    });
}

export function storeThreads(threads){
    return (dispatch) => dispatch({
        type: INITIALIZE_THREADS,
        payload: {
            threads,
            initial: true
        },
    });
}

export function addNewMessage( newMess ){
    return (dispatch) => dispatch({
        type: ADD_NEW_MESSAGE,
        payload: {
            newMess
        },
    });
}

export function removeNewMessages(){
    return (dispatch) => dispatch({
        type: REMOVE_NEW_MESSAGES,
        payload: {},
    });
}

export function openMiniThread( openThread ){
    return (dispatch) => dispatch({
        type: OPEN_MINI_THREAD,
        payload: {
            openThread
        },
    });
}

export function closeMiniThread(){
    return (dispatch) => dispatch({
        type: CLOSE_MINI_THREAD,
        payload: {},
    });
}

export function addMiniThread( id ){
    return (dispatch) => dispatch({
        type: ADD_MINI_THREAD,
        payload: {
            id
        },
    });
}

export function removeMiniThread( removeId ){
    return (dispatch) => dispatch({
        type: REMOVE_MINI_THREAD,
        payload: {
            removeId
        },
    });
}

export function storeNewMessages(threadId, messages, inChat, slice = true){
    return (dispatch) => dispatch({
        type: ADD_MESSAGES,
        payload: { threadId, messages, inChat, slice }
    })
}

export function removeThread(thread){
    return (dispatch) => dispatch({
        type: REMOVE_THREAD,
        payload: thread
    })
}

export function muteThread(thread){
    return (dispatch) => dispatch({
        type: MUTE_THREAD,
        payload: thread
    })
}

export function unmuteThread(thread){
    return (dispatch) => dispatch({
        type: UNMUTE_THREAD,
        payload: thread
    })
}

export function addThread(thread, message=null) {
    return (dispatch) => dispatch({
        type: ADD_THREAD,
        payload: {
            thread,
            message
        }
    });
}

export function markThreadAsSeen(threadId) {
    // instantly mark as read
    return (dispatch) => dispatch({
        type: MARK_THREAD_AS_SEEN,
        payload: threadId
    });
}

export function openSidebar(){
    return (dispatch) => dispatch({
        type: OPEN_SIDEBAR
    })
}

export function closeSidebar(){
    return (dispatch) => dispatch({
        type: CLOSE_SIDEBAR
    })
}
