import {Box, Button, Container, Typography, useMediaQuery} from '@mui/material';
import {RouterLink} from 'src/omnia/components/elements/router-link';
import {Seo} from 'src/omnia/components/elements/seo';
import {usePageView} from 'src/omnia/hooks/use-page-view';
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/system";

const Page = ({...rest}) => {
    const theme = useTheme();
    const mdUp = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { t } = useTranslation();

    console.log('Omnia Error')
    console.log(rest?.error?.message);
    console.log(rest?.error?.stack);

    return (
        <>
            <Seo title={t('pages.error_500_title')}/>
            <Box
                component="main"
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    py: '80px'
                }}
            >
                <Container maxWidth="lg">
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 6
                        }}
                    >
                        <Box
                            alt="Internal server error"
                            component="img"
                            src={theme?.config?.error_500?.view || "/assets/errors/error-500.png"}
                            sx={{
                                height: 'auto',
                                maxWidth: '100%',
                                width: 200
                            }}
                        />
                    </Box>
                    <Typography
                        align="center"
                        variant={mdUp ? 'h1' : 'h4'}
                    >
                        {t('pages.error_500_title')}
                    </Typography>
                    <Typography
                        align="center"
                        color="text.secondary"
                        sx={{mt: 0.5}}
                    >
                        {t('pages.error_500_subheader')}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 6
                        }}
                    >
                        <Button
                            component={RouterLink}
                            href={'/'}
                        >
                            {t('pages.back_home')}
                        </Button>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Page;

