import React, { useEffect, useState } from 'react';
import { useSettings } from "src/omnia/hooks/use-settings";
import { useIsMobile } from "src/omnia/hooks/use-is-mobile";
import PropTypes from "prop-types";
import { useTheme } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import {useAppMode} from "../../hooks/use-app-mode";

const DEFAULT_LOGOS = {
    main_light: '/assets/GROON_WORD_WHITE.svg',
    main_dark: '/assets/GROON_WORD_BLACK.svg',
    mobile_light: '/assets/GROON_SYMBOL_WHITE.svg',
    mobile_dark: '/assets/GROON_SYMBOL_BLACK.svg',
}

function Logo({ width, height, isHeader, color, ...rest }) {
    const theme = useTheme();
    const settings = useSettings();
    const { isMobile } = useIsMobile();
    const { isService, isWebsite, path } = useAppMode();
    const [logoSource, setLogoSource] = useState(theme.logo.dark);
    const [imageLoaded, setImageLoaded] = useState(false); // New state for tracking image load status

    useEffect(() => {
        const deviceIdentifier = isMobile ? "mobile" : "main";
        let source;
        if (color) {
            source = theme.config?.[deviceIdentifier + '_logo_' + color]?.view || DEFAULT_LOGOS[`${deviceIdentifier}_${color}`];
        } else {
            let forceLight = !(typeof (isHeader) === "undefined") && isHeader;
            if (forceLight || theme.palette.mode === "dark") {
                source = theme.config?.[deviceIdentifier + '_logo_light']?.view || DEFAULT_LOGOS[`${deviceIdentifier}_light`];
            } else {
                source = theme.config?.[deviceIdentifier + '_logo_dark']?.view || DEFAULT_LOGOS[`${deviceIdentifier}_dark`];
            }
        }
        setLogoSource(prev => {
            if(prev === source) return prev;
            setImageLoaded(false);
            return source;
        });
    }, [settings, isService, isMobile, width, isHeader, color, theme]);

    const evaluatedHeight = (settings.layout === "horizontal" ? 35 : (height ? parseInt(height) : 'auto'));

    return (
        <Link
            to={isWebsite ? '/' : (isService ? path : '/groon')}
            component={RouterLink}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <img
                alt="Logo"
                src={logoSource}
                onLoad={() => setImageLoaded(true)} // Set image as loaded when it successfully loads
                style={{
                    width: width ? width : 'auto',
                    height: evaluatedHeight || 'auto',
                    visibility: imageLoaded ? 'visible' : 'hidden', // Only show image when it's loaded
                }}
                {...rest}
            />
        </Link>
    )
}

Logo.propTypes = {
    width: PropTypes.any,
    color: PropTypes.oneOf(['light', 'dark']),
    height: PropTypes.any,
    isHeader: PropTypes.bool
}

export default Logo;
