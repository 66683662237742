import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Button, CircularProgress, Grid, Hidden, Typography} from "@mui/material";
import {useCreateSrc} from "src/omnia/hooks/use-create-src";
import useApi from "src/omnia/hooks/use-api";
import ReactPlayer from "react-player";
import { Document, Page } from 'react-pdf';
import {Lightbox} from "react-modal-image";
import useDimensions from "../../../hooks/use-dimensions";
import { pdfjs } from 'react-pdf';
import { imageTypes, videoTypes, docTypes } from 'src/omnia/utils/file-type-helpers';
import {useTranslation} from "react-i18next";

// TODO: this is completely retarded! We gotta move this to our server in Frankfurt!
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function FileGenericViewer({file, pdfWrapperRef, ...rest}){

    const [rawFile, setRawFile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [numPages, setNumPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [openedFile, setOpenedFile] = useState(null);
    const [pdfViewerWidth, setPdfWidth] = useState(100);
    const { getFile } = useApi();
    const getSrc = useCreateSrc();
    const { t } = useTranslation();
    const { width } = useDimensions();

    useEffect(() => {
        if(docTypes.includes(file['type'])){
            getFile(file)
                .then(file => {
                    // Set raw file
                    setRawFile(file);
                })
                .finally(() => {
                    setLoading(false);
                })
        } else {
            setLoading(false);
        }
    }, [file]);

    useEffect(() => {
        if(pdfWrapperRef && pdfWrapperRef.current){
            setPdfWidth(parseInt(pdfWrapperRef.current.offsetWidth));
        }
    }, [pdfWrapperRef, width]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const handlePageDown = () => {
        setCurrentPage(prev => prev - 1);
    }

    const handlePageUp = () => {
        setCurrentPage(prev => prev + 1);
    }

    // in case the component is loading
    if(loading){
        return (
            <div style={{height: 500, display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                <CircularProgress />
            </div>
        )
    }

    // Render images types
    if(imageTypes.includes(file['type'].toLowerCase())) {
        return (
            <>
                <img
                    alt={file?.name}
                    src={file?.view + '?quality=medium'}
                    style={{width: '100%', height: 'auto'}}
                    onClick={() => setOpenedFile(file?.view)}
                    {...rest}
                />
                {openedFile && (
                    <Lightbox
                        large={openedFile + '?quality=original'}
                        medium={openedFile + '?quality=highest'}
                        onClose={() => setOpenedFile(null)}
                    />
                )}
            </>
        )
    }

    // Render video types
    if(videoTypes.includes(file['type'].toLowerCase())) {
        return (
            <ReactPlayer
                url={getSrc(file.view)}
                controls={true}
                playing={true}
                width="100%"
                height="auto"
                playsinline={true}
                volume={1}
                muted={true}
                {...rest}
            />
        )
    }

    // Handle other types
    if(docTypes.includes(file['type'].toLowerCase())){

        if(rawFile === null)
            return (
                <div style={{height: 500, display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    <CircularProgress />
                </div>
            )

        return (
            <div style={{height: 'auto', minHeight: 500, width: '100%'}} {...rest}>
                <Document
                    file={rawFile}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={console.error}
                    style={{height: '100%'}}
                    error={(
                        <div style={{height: '100%', display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                            <Typography variant="caption">
                                {t('common.download_to_view')}
                            </Typography>
                        </div>
                    )}
                    renderMode="canvas"
                    loading={(
                        <div style={{height: '100%', minHeight: 500, display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                            <CircularProgress />
                        </div>
                    )}
                >
                    <Page
                        width={pdfViewerWidth}
                        pageNumber={currentPage}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                    />
                </Document>

                <Grid container spacing={2} sx={{p: 3}}>
                    <Grid item xs={6} sm={4}>
                        <Button disabled={currentPage === 1} onClick={handlePageDown} variant="text" size="small" >
                            {t('common.previous')}
                        </Button>
                    </Grid>
                    <Hidden only="xs">
                        <Grid item xs={6} sm={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography variant="caption">
                                {t('common.site_of', {site: currentPage, total: numPages})}
                            </Typography>
                        </Grid>
                    </Hidden>
                    <Grid item xs={6} sm={4} style={{textAlign: 'right'}}>
                        <Button disabled={currentPage === numPages} onClick={handlePageUp} variant="text" size="small" >
                            {t('common.next')}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        )

    }

    return (
        <div style={{height: 500, display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <Typography variant="caption" color="inherit">
                {t('common.can_not_show_file')}
            </Typography>
        </div>
    )
}

FileGenericViewer.propTypes = {
    file: PropTypes.object.isRequired,
    pdfWrapperRef: PropTypes.object
};

export default FileGenericViewer;