/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import produce from 'immer';
import _ from 'lodash';
import {
    ADD_THREAD,
    OPEN_SIDEBAR,
    INITIALIZE_THREADS,
    MARK_THREAD_AS_SEEN,
    REMOVE_THREAD,
    MUTE_THREAD,
    UNMUTE_THREAD,
    ADD_MESSAGES,
    CLOSE_SIDEBAR,
    ADD_MINI_THREAD,
    REMOVE_MINI_THREAD,
    OPEN_MINI_THREAD,
    CLOSE_MINI_THREAD,
    ADD_NEW_MESSAGE,
    REMOVE_NEW_MESSAGES,
    UPDATE_LAST_MESSAGE,
    UPDATE_ASSISTANT_OPEN,
    UPDATE_ASSISTANT_UNREAD
} from 'src/omnia/store/actions/messages-actions';

const initialState = {
    threads: [],
    threadsLoaded: false,
    messages: {},
    miniThreads: [],
    newMessages: [],
    openMiniThread: null,
    assistantOpen: false,
    sidebarOpen: true,
    isWriting: null,
    otherWritingUsers: [],
    unread: 0,
    unreadAI: 0,
    initialized: {}
};

const messagesReducer = (state = initialState, action) => {

    let tmp = null;
    let mergeSet = null;
    let unread = null;

    switch (action.type) {

        case UPDATE_ASSISTANT_UNREAD: {
            return produce(state, draft => {
                draft.unreadAI = action.payload;
            });
        }

        case UPDATE_ASSISTANT_OPEN: {
            return produce(state, draft => {
                draft.assistantOpen = action.payload;
            });
        }

        case UPDATE_LAST_MESSAGE: {
            const { threadId, message } = action.payload;
            return produce(state, draft => {
                const threadIndex = _.findIndex(state.threads, { id: parseInt(threadId) });
                if (threadIndex !== -1) {
                    draft.threads[threadIndex].last_message = message;
                }
            });
        }

        case ADD_NEW_MESSAGE:
            let { newMess } = action.payload;
            return produce(state, draft => {
                if(_.indexOf(state.newMessages, newMess) === -1)
                    draft.newMessages = _.concat(state.newMessages, newMess);
            });

        case REMOVE_NEW_MESSAGES:
            return produce(state, draft => {
                draft.newMessages = [];
            });

        case OPEN_MINI_THREAD:
            let { openThread } = action.payload;
            return produce(state, draft => {
                unread = 0;
                for(let i = 0; i < state.threads.length; i++){
                    if(state.threads[i].id === openThread?.id){
                        draft.threads[i]['unread_count'] = 0;
                    } else {
                        unread += state.threads[i]['unread_count'];
                    }
                }
                draft.unread = unread;
                draft.openMiniThread = openThread;
            })

        case CLOSE_MINI_THREAD:
            return produce(state, draft => {
                draft.openMiniThread = null;
            })

        case INITIALIZE_THREADS:
            let messageSet = {};
            let initSet = {};
            let { threads, initial } = action.payload;

            unread = 0;
            for(let i = 0; i < threads.length; i++){
                // accumulate the unread count
                unread += threads[i]['unread_count'];
                // create an index for each thread for the messages
                if(initial){
                    messageSet[threads[i].id] = [];
                    initSet[threads[i].id] = false;
                }
            }
            mergeSet = state.threads
                .filter(t => !threads.map(a => a.id).includes(t.id))
                .concat(threads)
                .sort((a, b) => {
                    if((typeof(a.last_message) !== "undefined") && (typeof(b.last_message) !== "undefined")){
                        if((a.last_message !== null) && (b.last_message !== null)){
                            a = (new Date(a.last_message.created_at)).getTime();
                            b = (new Date(b.last_message.created_at)).getTime();
                            return ((a > b)) ? -1 : 1 ;
                        }
                    }
                    return 1;
                });
            // produce new state
            return produce(state, draft => {
                draft.threads = mergeSet;
                if(initial) {
                    draft.messages = messageSet;
                    draft.initialized = initSet;
                }
                draft.unread = unread;
                draft.threadsLoaded = true;
            })

        case ADD_MINI_THREAD:
            let { id } = action.payload;

            return produce(state, draft => {
                if(_.indexOf(state.miniThreads, id) === -1)
                    draft.miniThreads = _.concat(state.miniThreads, id);
            })

        case REMOVE_MINI_THREAD:
            let { removeId } = action.payload;
            return produce(state, draft => {
                draft.miniThreads = state.miniThreads.filter(t => t !== removeId);
            })

        case ADD_THREAD:
            let { thread, message } = action.payload;
            if(state.threads.filter(t => t.id === thread.id).length === 0){
                mergeSet = state.threads.concat([thread]).sort((a, b) => {
                    if((typeof(a.last_message) !== "undefined") && (typeof(b.last_message) !== "undefined")){
                        if((a.last_message !== null) && (b.last_message !== null)){
                            a = (new Date(a.last_message.created_at)).getTime();
                            b = (new Date(b.last_message.created_at)).getTime();
                            return ((a > b)) ? -1 : 1 ;
                        }
                    }
                    return 1;
                });
                return produce(state, draft => {
                    draft.threads = mergeSet;
                    // add this index
                    draft.messages[thread.id] = (message === null) ? [] : [message];
                    draft.initialized[thread.id] = true;
                })
            } else {
                tmp = state.threads.indexOf(state.threads.find(t => t.id === thread.id));
                if(tmp === -1)
                    return state;
                return produce(state, draft => {
                    draft.threads[tmp] = thread;
                    draft.messages[thread.id] = (message === null) ? state.messages[thread.id] : state.messages[thread.id].concat([message]);
                })
            }

        case REMOVE_THREAD:
            return produce(state, draft => {
                draft.threads = state.threads.filter(t => t.id !== action.payload.id);
                draft.messages = _.omit(state.messages, [action.payload.id]);
                draft.initialized = _.omit(state.initialized, [action.payload.id]);
            })

        case ADD_MESSAGES:
            let { threadId, messages, inChat, slice } = action.payload;

            tmp = state.threads.indexOf(state.threads.find(t => t.id === threadId));
            if(tmp === -1)
                return state;

            return produce(state, draft => {
                // create new messages
                let newMessages = _.uniqBy(state.messages[threadId].concat(messages).sort((a, b) => {
                    a = (new Date(a.created_at)).getTime();
                    b = (new Date(b.created_at)).getTime();
                    return ((a > b)) ? 1 : -1 ;
                }), function (e) {
                    return e.id;
                });
                if(slice && newMessages.length > 30)
                    newMessages = newMessages.slice(newMessages.length - 29, newMessages.length + 1);
                // append message to the redux messages
                draft.messages[threadId] = newMessages;
                // update last message of the thread
                draft.threads[tmp]['last_message'] = newMessages[newMessages.length - 1];
                // if passive adding increase unread count
                if(!inChat){
                    // increment thread unread count and global unread count
                    draft.threads[tmp]['unread_count'] = state.threads[tmp]['unread_count'] + messages.length;
                    draft.unread = state.unread + messages.length;
                } else {
                    // save initialized
                    draft.initialized[threadId] = true;
                }
            })

        case MUTE_THREAD:
            // get index of thread of interest
            tmp = state.threads.indexOf(state.threads.find(t => t.id === action.payload.id));
            if(tmp === -1)
                return state;
            return produce(state, draft => {
                draft.threads[tmp]['muted'] = true;
            })

        case UNMUTE_THREAD:
            tmp = state.threads.indexOf(state.threads.find(t => t.id === action.payload.id));
            if(tmp === -1)
                return state;
            return produce(state, draft => {
                draft.threads[tmp]['muted'] = false;
            })

        case MARK_THREAD_AS_SEEN:
            return produce(state, draft => {
                let index = state.threads.findIndex(t => t.id === action.payload);
                if(index === -1)
                    return state;
                draft.threads[index]['unread_count'] = 0;
                draft.unread = state.unread - state.threads[index]['unread_count'];
            })

        case CLOSE_SIDEBAR:
            return produce(state, draft => {
                draft.sidebarOpen = false;
            })

        case OPEN_SIDEBAR:
            return produce(state, draft => {
                draft.sidebarOpen = true;
            })

        default: {
            return state;
        }

    }

};

export default messagesReducer;