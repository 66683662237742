import ContactsIcon from '@untitled-ui/icons-react/build/esm/Users03';
import {IconButton, SvgIcon, Tooltip} from '@mui/material';
import {usePopover} from 'src/omnia/hooks/use-popover';
import {ContactsPopover} from './contacts-popover';
import {useTranslation} from "react-i18next";
import {useSecurityCheck} from "../../../../hooks/use-security-check";

export const ContactsButton = () => {

    const popover = usePopover();
    const { t } = useTranslation();
    const { hasRights } = useSecurityCheck();

    return (
        <>
            <Tooltip title={t("core.contacts")}>
                <IconButton
                    onClick={popover.handleOpen}
                    ref={popover.anchorRef}
                >
                    <SvgIcon>
                        <ContactsIcon/>
                    </SvgIcon>
                </IconButton>
            </Tooltip>
            <ContactsPopover
                anchorEl={popover.anchorRef.current}
                onClose={popover.handleClose}
                open={popover.open}
            />
        </>
    );
};
