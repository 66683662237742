import React, {useCallback, useEffect, useState, useMemo, useRef} from "react";
import { isDesktop } from "react-device-detect";
import { toast } from "react-hot-toast";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

export const useNotifications = () => {
    const theme = useTheme();
    const [notifications, setNotifications] = useState([]);
    const [screenIsVisible, setScreenIsVisible] = useState(true);
    const messages = useSelector(state => state.client.currentMessages);
    const dispatch = useDispatch();
    const themeRef = useRef();
    themeRef.current = theme;

    const closeAllNotifications = useCallback(() => {
        if (isScreenVisible() && desktopNotificationsAllowed()) {
            notifications.forEach((notification) => notification.close());
            setNotifications([]);
        }
    }, [notifications]);

    const desktopNotificationsAllowed = useCallback(() => {
        if (!isDesktop) return false;
        if (typeof window === "undefined") return false;
        if (!("Notification" in window)) return false;

        if (Notification.permission === "granted") {
            return true;
        } else {
            if (Notification.permission === "default") Notification.requestPermission();
            return false;
        }
    }, []);

    const isScreenVisible = useCallback(() => {
        return document.visibilityState === "visible";
    }, []);

    const flashDesktopNotification = useCallback((message, title) => {
        console.log('flash desktop')
        if (desktopNotificationsAllowed()) {
            const tmp = new Notification(title, {
                title,
                body: message,
                icon: "/favicon.ico",
                dir: "ltr",
            });
            setNotifications((prev) => prev.concat([tmp]));
            document.addEventListener("visibilitychange", closeAllNotifications);
            tmp.addEventListener('close', () => {
                document.removeEventListener("visibilitychange", closeAllNotifications);
            });
        }
    }, [desktopNotificationsAllowed, closeAllNotifications]);

    const notify = useCallback((message, variant = "default", asDesktop = false, duration = 3000) => {

        // Check for desktop notification to be thrown
        if (!isScreenVisible() && asDesktop && desktopNotificationsAllowed())
            flashDesktopNotification(message, "GROON");

        // Check if this message is still showing

        const commonProps = {
            duration: duration,
            id: message,
            style: { backgroundColor: themeRef.current.palette.background.default },
        };

        const content = (
            <Typography variant="body1" color={themeRef.current.palette.text.primary}>
                {message}
            </Typography>
        );

        if (variant === "success") {
            toast.success(content, commonProps);
        } else if (variant === "error") {
            toast.error(content, commonProps);
        } else {
            toast(content, commonProps);
        }
    }, [isScreenVisible, desktopNotificationsAllowed, flashDesktopNotification]);

    const notifySuccess = useCallback((t) => {
        {
            const successMessages = [
                'notify.success',
                'notify.success1',
                'notify.success2',
                'notify.success3',
                'notify.success4',
                'notify.success5',
                'notify.success6',
                'notify.success7',
                'notify.success8',
                'notify.success9',
                'notify.success10'
            ];
            const randomIndex = Math.floor(Math.random() * successMessages?.length);
            const selectedMessageKey = successMessages?.[randomIndex];
            notify(t(selectedMessageKey || 'notify.success'), 'success');
        }
    }, [notify])

    const flashStatusChange = useCallback(
        (message) => {
            toast(
                <Typography variant="body1" color={themeRef.current.palette.text.primary}>
                    {message}
                </Typography>,
                {
                    style: {
                        backgroundColor: themeRef.current.palette.background.default,
                    },
                }
            );
        },
        []
    );

    useEffect(() => {
        const onVisibilityChange = () => {
            const isVisible = document.visibilityState === "visible";
            if (screenIsVisible !== isVisible) setScreenIsVisible(isVisible);
        };
        document.addEventListener("visibilitychange", onVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", onVisibilityChange);
        };
    }, [screenIsVisible]);

    return {
        screenIsVisible,
        notify,
        notifySuccess,
        flashStatusChange,
        flashDesktopNotification,
        isScreenVisible,
    };
};
